module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-remark-images-virtual-e52dffcaa7/0/cache/gatsby-remark-images-npm-7.14.0-a55a0fccfe-a2a9acf06e.zip/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-analytics-virtual-c8acb921f7/0/cache/gatsby-plugin-google-analytics-npm-5.14.0-ac24e21af3-8ac12c1b5d.zip/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"anonymize":false,"respectDNT":false,"trackingId":"UA-133298317-1","head":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-6a7789d4d5/0/cache/gatsby-plugin-manifest-npm-5.14.0-8a89b421cf-72462d7503.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"pseudorandom","short_name":"pseudorandom","start_url":"/","background_color":"#ffffff","theme_color":"#007acc","display":"minimal-ui","icon":"static/favicon.png","include_favicon":true,"icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"21c8e09cfda63c48126f048dcb15247c"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-offline-virtual-ef45b6504f/0/cache/gatsby-plugin-offline-npm-6.14.0-87805d241e-4be494daf0.zip/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-typography-virtual-fafafd4c3b/0/cache/gatsby-plugin-typography-npm-5.14.0-c9f7019ac7-4508bc7e90.zip/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-fb02e63504/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
